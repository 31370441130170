import React from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/headerbanner"
import PartnerCard from "../components/Card/partnercard"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEOHeader from "../components/seo-header"

const CommunityParters = () => {
  const data = useStaticQuery(graphql`
    query CommunityParters {
      allPartner {
        edges {
          node {
            order
            partnerDesc
            partnerImg
            partnerSlug
            partnerTitle
            partnerFullDesc
            id
          }
        }
      }
    }
  `)
  const partners = data.allPartner.edges
  return (
    <div>
      <SEOHeader
        title="Discounts & Perks from GoFloaters"
        description="GoFloaters doesn’t just save money on your workspaces, It can save money for your business. That’s right, get access to an ultimate curation of deals."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <HeaderBanner>
          <h1>Community Benefits</h1>
        </HeaderBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>
                Deals and Discounts worth ₹1 Crore<br></br>
              </h1>
              <br></br>
              GoFloaters doesn’t just save money on your workspaces, It can save
              money for your business. That’s right, get access to an ultimate
              curation of deals from products & services that can help propel
              your business.<br></br>
              <br></br>
              How to claim these benefits? Click the button below to fill a form
              and receive the coupon on your inbox. Simple!
            </div>

            {partners.map(partner => {
              return (
                <PartnerCard
                  partnerImg={partner.node.partnerImg}
                  partnerName={partner.node.partnerImg}
                  partnerDesc={partner.node.partnerDesc}
                  partnerLink={"/partner/" + partner.node.partnerSlug}
                />
              )
            })}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CommunityParters
